import { render, staticRenderFns } from "./Error404.vue?vue&type=template&id=2061e331&scoped=true&"
import script from "./Error404.vue?vue&type=script&lang=js&"
export * from "./Error404.vue?vue&type=script&lang=js&"
import style0 from "./Error404.vue?vue&type=style&index=0&id=2061e331&scoped=true&lang=css&"
import style1 from "./Error404.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2061e331",
  null
  
)

export default component.exports